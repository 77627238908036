#main-nav {
  font-family: 'Merriweather Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  float: right;
  margin-top: 1em;
  text-align: right;
}

/*** hamburger ***/

.main-menu-btn {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  text-indent: 28px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* hamburger icon */
.main-menu-btn-icon, .main-menu-btn-icon:before, .main-menu-btn-icon:after {
  position: absolute;
  top: 50%;
  left: 2px;
  height: 2px;
  width: 24px;
  background: #bbb;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.main-menu-btn-icon:before {
  content: '';
  top: -7px;
  left: 0;
}

.main-menu-btn-icon:after {
  content: '';
  top: 7px;
  left: 0;
}

/* x icon */
#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
  height: 0;
  background: transparent;
}
#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
  top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* hide menu state checkbox offscreen (so it stays visible to screen readers) */
#main-menu-state {
  position: absolute;
  top: -99999px;
}
/* hide the menu in mobile view */
#main-menu-state:not(:checked) ~ #main-menu {
  display: none;
}
#main-menu-state:checked ~ #main-menu {
  display: block;
}
@media (min-width: 768px) {
  /* hide the button in desktop view */
  .main-menu-btn {
    position: absolute;
    top: -99999px;
  }
  /* always show the menu in desktop view */
  #main-menu-state:not(:checked) ~ #main-menu {
    display: block;
  }
}
/*** end hamburger ***/

#main-nav ul {
    background: $darker-gray;
    border-bottom: none;
    border-top: none;
    color: $white;
}

#main-nav ul li.top-level {
  background: $black;
}
#main-nav ul a.top-level:hover,
#main-nav ul a.highlighted {
  background: $secondary-color;
}

#main-nav ul li {
    border-top-color: darken($darker-gray, 20%);
}

#main-nav ul li a {
    border-left: 0;
}

#main-nav ul li.menu-third-level {
  // background-color: darken($darker-gray, 8%);
  border-top: none;
  margin-left: 20px;
}
#main-nav ul li.menu-third-level a {
  padding-left: 10px;
}

li.menu-third-level:before {
    content: "";
    border-left: #FC6D58 2px solid;
    height: 15px;
    position: absolute;
    top: 13px;
}

#main-nav a {
    color: $white;
}

.sm-mint a span.sub-arrow {
    // position: absolute;
    // top: 50%;
    // margin-top: -13px;
    // left: auto;
    // right: 4px;
    // width: 34px;
    // height: 34px;
    // overflow: hidden;
    // font: bold 14px/34px monospace !important;
    // text-align: center;
    // text-shadow: none;
    background: $black;
    // border-radius: 4px;
}

/* scrolling confluence navigation */

.scrolling-nav {
  display: flex;
  justify-content: space-between;
  font-size: .8em;
  margin-bottom: 4rem;
}

.scrolling-nav a span {
  color: $dark-gray;
}

.scrolling-next {
  text-align: right;
  margin-left: 14px;
}
.scrolling-prev {
  margin-right: 14px;
}

/*** desktop menu ***/

@media (min-width: 768px) { /*browser style*/

    .sm-mint a span.sub-arrow {
      background: rgba(0,0,0,0);
    }

    #main-nav {
      line-height: 0;
      text-align: right;
    }

    #main-nav a {
      color: $white;
    }

    #main-nav .top-level>a {
      height: 39px;
    }

    #main-nav a.highlighted {
      color: $black;
    }
    #main-nav a:hover {
      color: $black;
      border-bottom: 4px solid $darker-gray;
    }
    #main-nav a.has-submenu:hover {
      border-bottom: blue;
    }

    .sm-mint a.highlighted span.sub-arrow {
      border-color: $black transparent transparent transparent;
    }

    #main-menu {
      display: inline-block;
    }

    .sm-mint ul {
      padding: 0;
    }

    .sm-mint a.highlighted {
      background-color: $secondary-color;
    }

    #main-nav ul.sm-nowrap li {
      border-top: $dark-gray 1px solid;
      // border-bottom: $dark-gray 1px solid;
    }
    #main-nav ul.sm-nowrap li:first-of-type,
    #main-nav ul.sm-nowrap li.menu-third-level {
      border-top: none;
    }

    li.menu-third-level {
      // background-color: darken($darker-gray, 8%);
      // border-left: 3px $secondary-color solid;
      // margin-left: 21px;
      // margin-right: 21px;
    }

    li.menu-third-level:before {
      top: 11px;
    }

    li.menu-third-level a,
    li.menu-third-level a:hover {
      padding-left: 10px;
    }

} /* end min768 */

@media screen and (min-width: 64em) { /*1024px*/
  #main-nav {
      padding-right: 5em;
  }
}
