/* Contact Modal */

.reveal {
  max-width:  40rem;
}

.reveal,
.reveal label {
  color: $white;
}

.reveal .button[type="submit"] {
  background-color: $secondary-color;
  margin-top: 1em;
}
.reveal .button[type="submit"]:hover {
  background-color: $black;
}

input#mailinglist {
  width: auto;
}

#form-messages {
  margin-top: 1.5em;
}

textarea#message {
    height: 7em;
    font-size: .8rem;
}

#hidDiv {
  // background-color: pink;
  display: none;
}

/* from treehouse tut */

.success {
  padding: 1em;
  margin-bottom: 0.75rem;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  color: #468847;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
}

.error {
  padding: 1em;
  margin-bottom: 0.75rem;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  color: #b94a48;
  background-color: #f2dede;
  border: 1px solid rgba(185, 74, 72, 0.3);
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
}
