.sm-mint {
  border-top: 2px solid #FC6D58;
  border-bottom: 2px solid #FC6D58;
  background: white;
}
.sm-mint a, .sm-mint a:hover, .sm-mint a:focus, .sm-mint a:active {
  padding: 13px 20px;
  /* make room for the toggle button (sub indicator) */
  padding-right: 58px;
  color: #333333;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 17px;
  text-decoration: none;
}
.sm-mint a.current {
  font-weight: bold;
}
.sm-mint a.disabled {
  color: #cccccc;
}
.sm-mint a span.sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 4px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 14px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  background: rgba(141, 184, 99, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.sm-mint a.highlighted span.sub-arrow:before {
  display: block;
  content: '-';
}
.sm-mint li {
  border-top: 1px solid rgba(141, 184, 99, 0.2);
}
.sm-mint > li:first-child {
  border-top: 0;
}
.sm-mint ul {
  background: rgba(141, 184, 99, 0.2);
}
.sm-mint ul a, .sm-mint ul a:hover, .sm-mint ul a:focus, .sm-mint ul a:active {
  font-size: 14px;
  border-left: 8px solid transparent;
}
.sm-mint ul ul a,
.sm-mint ul ul a:hover,
.sm-mint ul ul a:focus,
.sm-mint ul ul a:active {
  border-left: 16px solid transparent;
}
.sm-mint ul ul ul a,
.sm-mint ul ul ul a:hover,
.sm-mint ul ul ul a:focus,
.sm-mint ul ul ul a:active {
  border-left: 24px solid transparent;
}
.sm-mint ul ul ul ul a,
.sm-mint ul ul ul ul a:hover,
.sm-mint ul ul ul ul a:focus,
.sm-mint ul ul ul ul a:active {
  border-left: 32px solid transparent;
}
.sm-mint ul ul ul ul ul a,
.sm-mint ul ul ul ul ul a:hover,
.sm-mint ul ul ul ul ul a:focus,
.sm-mint ul ul ul ul ul a:active {
  border-left: 40px solid transparent;
}

@media (min-width: 768px) {
  /* Switch to desktop layout
  -----------------------------------------------
     These transform the menu tree from
     collapsible to desktop (navbar + dropdowns)
  -----------------------------------------------*/
  /* start... (it's not recommended editing these rules) */
  .sm-mint ul {
    position: absolute;
    width: 12em;
  }

  .sm-mint li {
    float: left;
  }

  .sm-mint.sm-rtl li {
    float: right;
  }

  .sm-mint ul li, .sm-mint.sm-rtl ul li, .sm-mint.sm-vertical li {
    float: none;
  }

  .sm-mint a {
    white-space: nowrap;
  }

  .sm-mint ul a, .sm-mint.sm-vertical a {
    white-space: normal;
  }

  .sm-mint .sm-nowrap > li > a, .sm-mint .sm-nowrap > li > :not(ul) a {
    white-space: nowrap;
  }

  /* ...end */
  .sm-mint {
    border-top: 0;
    background: transparent;
  }
  .sm-mint a, .sm-mint a:hover, .sm-mint a:focus, .sm-mint a:active, .sm-mint a.highlighted {
    padding: 11px 20px;
    color: #333333;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
  }
  .sm-mint a:hover, .sm-mint a:focus, .sm-mint a:active {
    background: #FC6D58;
    color: white;
  }
  .sm-mint a.highlighted {
    background: #f6ffed;
    color: #333333;
    -webkit-box-shadow: 0 4px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 4px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.25);
  }
  .sm-mint a.disabled {
    background: transparent;
    color: #cccccc;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .sm-mint a.has-submenu {
    padding-right: 34px;
  }
  .sm-mint a span.sub-arrow {
    top: 50%;
    margin-top: -3px;
    right: 20px;
    width: 0;
    height: 0;
    border-width: 6px 4.02px 0 4.02px;
    border-style: solid dashed dashed dashed;
    border-color: #FC6D58 transparent transparent transparent;
    background: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
  .sm-mint a:hover span.sub-arrow, .sm-mint a:focus span.sub-arrow, .sm-mint a:active span.sub-arrow {
    border-color: white transparent transparent transparent;
  }
  .sm-mint a.highlighted span.sub-arrow {
    border-color: #FC6D58 transparent transparent transparent;
  }
  .sm-mint a.disabled span.sub-arrow {
    border-color: #FC6D58 transparent transparent transparent;
  }
  .sm-mint a.highlighted span.sub-arrow:before {
    display: none;
  }
  .sm-mint li {
    border-top: 0;
  }
  .sm-mint ul {
    border: 0;
    padding: 8px 0;
    background: #f6ffed;
    -webkit-border-radius: 0 4px 4px 4px;
    -moz-border-radius: 0 4px 4px 4px;
    -ms-border-radius: 0 4px 4px 4px;
    -o-border-radius: 0 4px 4px 4px;
    border-radius: 0 4px 4px 4px;
    -webkit-box-shadow: 0 4px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 4px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.25);
  }
  .sm-mint ul ul {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
  }
  .sm-mint ul a, .sm-mint ul a:hover, .sm-mint ul a:focus, .sm-mint ul a:active, .sm-mint ul a.highlighted {
    border: 0 !important;
    padding: 10px 20px;
    color: #333333;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
  .sm-mint ul a:hover, .sm-mint ul a:focus, .sm-mint ul a:active, .sm-mint ul a.highlighted {
    background: #FC6D58;
    color: white;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .sm-mint ul a.disabled {
    background: transparent;
    color: #b3b3b3;
  }
  .sm-mint ul a.has-submenu {
    padding-right: 20px;
  }
  .sm-mint ul a span.sub-arrow {
    right: 10px;
    margin-top: -4.02px;
    border-width: 4.02px 0 4.02px 6px;
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent #FC6D58;
  }
  .sm-mint ul a:hover span.sub-arrow, .sm-mint ul a:focus span.sub-arrow, .sm-mint ul a:active span.sub-arrow, .sm-mint ul a.highlighted span.sub-arrow {
    border-color: transparent transparent transparent white;
  }
  .sm-mint ul a.disabled span.sub-arrow {
    border-color: transparent transparent transparent #FC6D58;
  }
  .sm-mint span.scroll-up,
  .sm-mint span.scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    background: #f6ffed;
    height: 20px;
  }
  .sm-mint span.scroll-up-arrow, .sm-mint span.scroll-down-arrow {
    position: absolute;
    top: 6px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-width: 0 6px 8px 6px;
    border-style: dashed dashed solid dashed;
    border-color: transparent transparent #FC6D58 transparent;
  }
  .sm-mint span.scroll-down-arrow {
    border-width: 8px 6px 0 6px;
    border-style: solid dashed dashed dashed;
    border-color: #FC6D58 transparent transparent transparent;
  }
  .sm-mint.sm-rtl a.has-submenu {
    padding-right: 20px;
    padding-left: 34px;
  }
  .sm-mint.sm-rtl a span.sub-arrow {
    right: auto;
    left: 20px;
  }
  .sm-mint.sm-rtl.sm-vertical {
    border-right: 0;
    border-left: 2px solid #FC6D58;
  }
  .sm-mint.sm-rtl.sm-vertical a {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -ms-border-radius: 0 4px 4px 0;
    -o-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
  }
  .sm-mint.sm-rtl.sm-vertical a.has-submenu {
    padding: 10px 20px;
  }
  .sm-mint.sm-rtl.sm-vertical a span.sub-arrow {
    right: auto;
    left: 10px;
    border-width: 4.02px 6px 4.02px 0;
    border-style: dashed solid dashed dashed;
    border-color: transparent #FC6D58 transparent transparent;
  }
  .sm-mint.sm-rtl.sm-vertical a:hover span.sub-arrow, .sm-mint.sm-rtl.sm-vertical a:focus span.sub-arrow, .sm-mint.sm-rtl.sm-vertical a:active span.sub-arrow, .sm-mint.sm-rtl.sm-vertical a.highlighted span.sub-arrow {
    border-color: transparent white transparent transparent;
  }
  .sm-mint.sm-rtl.sm-vertical a.disabled span.sub-arrow {
    border-color: transparent #FC6D58 transparent transparent;
  }
  .sm-mint.sm-rtl ul {
    -webkit-border-radius: 4px 0 4px 4px;
    -moz-border-radius: 4px 0 4px 4px;
    -ms-border-radius: 4px 0 4px 4px;
    -o-border-radius: 4px 0 4px 4px;
    border-radius: 4px 0 4px 4px;
  }
  .sm-mint.sm-rtl ul a {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    border-radius: 0 !important;
  }
  .sm-mint.sm-rtl ul a.has-submenu {
    padding: 10px 20px !important;
  }
  .sm-mint.sm-rtl ul a span.sub-arrow {
    right: auto;
    left: 10px;
    border-width: 4.02px 6px 4.02px 0;
    border-style: dashed solid dashed dashed;
    border-color: transparent #FC6D58 transparent transparent;
  }
  .sm-mint.sm-rtl ul a:hover span.sub-arrow, .sm-mint.sm-rtl ul a:focus span.sub-arrow, .sm-mint.sm-rtl ul a:active span.sub-arrow, .sm-mint.sm-rtl ul a.highlighted span.sub-arrow {
    border-color: transparent white transparent transparent;
  }
  .sm-mint.sm-rtl ul a.disabled span.sub-arrow {
    border-color: transparent #FC6D58 transparent transparent;
  }
  .sm-mint.sm-vertical {
    border-bottom: 0;
    border-right: 2px solid #FC6D58;
  }
  .sm-mint.sm-vertical a {
    padding: 10px 20px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    -ms-border-radius: 4px 0 0 4px;
    -o-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
  }
  .sm-mint.sm-vertical a:hover, .sm-mint.sm-vertical a:focus, .sm-mint.sm-vertical a:active, .sm-mint.sm-vertical a.highlighted {
    background: #FC6D58;
    color: white;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .sm-mint.sm-vertical a.disabled {
    background: transparent;
    color: #cccccc;
  }
  .sm-mint.sm-vertical a span.sub-arrow {
    right: 10px;
    margin-top: -4.02px;
    border-width: 4.02px 0 4.02px 6px;
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent #FC6D58;
  }
  .sm-mint.sm-vertical a:hover span.sub-arrow, .sm-mint.sm-vertical a:focus span.sub-arrow, .sm-mint.sm-vertical a:active span.sub-arrow, .sm-mint.sm-vertical a.highlighted span.sub-arrow {
    border-color: transparent transparent transparent white;
  }
  .sm-mint.sm-vertical a.disabled span.sub-arrow {
    border-color: transparent transparent transparent #FC6D58;
  }
  .sm-mint.sm-vertical ul {
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -ms-border-radius: 4px !important;
    -o-border-radius: 4px !important;
    border-radius: 4px !important;
  }
  .sm-mint.sm-vertical ul a {
    padding: 10px 20px;
  }
}
