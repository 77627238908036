@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
// @import 'magnific-main';
@import 'fancybox';
@import 'sm-core-css';
@import 'sm-mint';
@import 'contact';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-button-group;
// @include foundation-callout;
@include foundation-close-button;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
// @include foundation-menu;
@include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
// @include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

.row {
  max-width: 58.125rem;
}

h1 {
  color: $medium-gray;
  font-size: 1.3em;
  text-align: center;
  width: auto;
  text-transform: uppercase;
  margin-top: 2em;
  margin-bottom: 2em;
}

a:hover, a:focus {
  color: $white;
}

/* Paintings */

.painting-vert,
.painting-horz {
  margin: 4rem auto 8rem;
}

.triptych .painting-vert,
.triptych .painting-horz {
  margin: 4rem auto 4rem;
}

.triptych-single-caption {
  margin-bottom: 8em;
}

.triptych .inner-row {
  margin: 0;
}

// this is the default, but was overridden
.triptych .inner-row .small-offset-2 {
  margin-left: 16.66667%;
}

.triptych .inner-row .small-offset-3 {
  margin-left: 25%;
}

.triptych-single-caption .painting-vert,
.triptych-single-caption .painting-horz {
  margin: 4rem auto 0;
}

.painting-vert img,
.painting-horz img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 78vh;
  vertical-align: middle;
}

.home-page .painting-horz img {
  max-height: 70vh;
}

.home-page .external-link {
  text-align: center;
}

.painting-info,
.open-gallery-link {
  font-size: .9em;
  margin: 1em auto;
  text-align: center;
}

.page-wrap-white .painting-info {
  color: $black
}

a.open-gallery-link {
  margin-top: -.4rem;
  display: block;
  color: $secondary-color;
}

a.open-gallery-link:hover {
  color: $medium-gray;
}

.painting-title {
  font-style: italic;
}

/* Navigation */

@import 'navigation';

ul#main-menu {
  z-index: 7777; //so lightbox doesn't tuck under
}

/* Site Title */

.site-title {
  color: $white;
  position: absolute;
  left: 0;
  top: 0;
  padding: 1.25em;
  font-weight: 700;
  background-color: $black;
  width: 100%;

  @include breakpoint(large) {
    padding-left: 5em;
  }
}

.site-title a {
  color: $white;
}

.site-title a:hover {
  color: $secondary-color;
}

/*  */

audio {
  display: block;
  margin: 0 auto .5em;
}

.installation .painting-info {
  margin-bottom: 3em;
}

/* FancyBox */

.hidden {
  display: none;
}

.fancybox-nav {
  width: 60px;
}

.fancybox-nav span {
  visibility: visible;
  opacity: 0.5;
}

.fancybox-nav:hover span {
  opacity: 1;
}

.fancybox-next {
  right: -60px;
}

.fancybox-prev {
  left: -60px;
}

/* Video */

.flex-video {
  margin: 4em 0;
}

/* Bio */

#carla-img {
  margin-top: .5em
}

.bio {
  margin-bottom: 5em
}

p.photocredit {
  font-size: 8px;
  margin-top: 9px;
  text-align: right;
  letter-spacing: 0.3px;
  color: #e6e6e6;
}

@media screen and (max-width: 639px) {
  p.photocredit {
    text-align: left;
  }
}

/* Contact Modal */
// see _contact.scss

#four-o-four-menu {
  margin: 0 auto;
}

#four-o-four-menu li {
  color: $secondary-color;
}

/* Installation Page */

.statement {
  margin-bottom: 4em;
}

/* Installation Page */

#binary-translate {
  margin-bottom: 4em;
}

#binary-translate h2, h3 {
  color: $medium-gray;
  font-weight: 700;
}

#binary-translate h2 {
  font-size: 1.2em;
}

#binary-translate h3 {
  color: #f4cc6e;
  font-size: 1em;
  margin-top: 1.1em;
}

#binary-translate p {
  padding-left: 22px;
  text-indent: -22px;
  margin: 0;
}

#binary-translate .space-above {
  margin-top: .8em;
}

/* Flipbook */
.flipbook-intro {
  border-top: 1px solid #313131;
  text-align: center;
  padding-top: 30px;
}

.flipbook-purchase {
  text-align: center;
}

.flipbook-purchase:last-of-type {
  margin-bottom: 4em;
}

#df_manual_thumb {
  display: block;
  height: 360px;
  margin: 30px auto 30px;
  width: 285px;
}

.df-lightbox-wrapper,
.df-container {
  background-color: rgba(0, 0, 0, .5) !important;
}

.df-ui-next:before {
  color: #7f7f7f !important;
}

/* Maintenance Page */

.maintenance {
  padding-top: 6em;
}

.maintenance h5 {
  text-align: center;
  margin-top: -1em;
}

.maintenance img {
  margin-top: 6em;
}

/* Footer */

.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -4rem;
}

#footer, .push {
  height: 4rem;
}

#footer {
  background: $black;
  border-top: 1px solid #313131;
  color: $white;
  text-align: center;
  padding: 1rem;
}

#footer i {
  color: $medium-gray;
}

#footer a:hover i {
  color: $secondary-color;
}